import React from "react"
import Layout from "../components/layouts/investments_layout"
import Meta from "../components/meta"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import ImageCard from "../components/image_card"


const Commercial = ({ data }) => {
  return (
    <Layout
      logo="logo_svg_commercial_orange.svg"
    >
      <Meta
        title="threesixty Commercial Finance Ltd"
        description="We have a breadth of knowledge and experience working for banks and funders with our expertise as accountants and insolvency/restructuring experts."
      />
      <Container>
        <Row className="mb-4">
          <Col>
            <h1 className="fw-bold">
              threesixty Commercial Finance Ltd
            </h1>
            <p>
              We have a breadth of knowledge and experience working for banks
              and funders. In addition, our expertise as accountants and
              insolvency/restructuring experts means that we can ensure that any
              proposal is portrayed in the right light for a lender to review.
              Our vast knowledge of the lending market and the personnel within
              it means that we can pitch the deal to the right person – very
              often going to the top of an organisation for an answer. That way,
              we save a lot of time and trouble.
            </p>
            <blockquote className="blockquote text-justify mt-4 blockquote-commercial">
              <p className="mb-0 text-justify">
                We have worked with threesixty Commercial Finance Ltd for a number of years and the relationship has worked really well for both sides.
              </p><br />
              <p className="mb-0 text-justify">
                We find the team to be transparent, experts in their respective fields and easy to work with - BFS is looking forward to supporting them further over the coming years.
              </p><br />
              <footer className="blockquote-footer">
                Ben Smith{" "}
                <cite title="UK Corporate Sales Director at Bibby Financial Services [UK] Ltd">
                  UK Corporate Sales Director at Bibby Financial Services [UK] Ltd
                </cite>
              </footer>
            </blockquote>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Offerings</h2>
            <Row xs={1} md={3} className="g-4">
              <Col>
                <ImageCard
                  image={data.invoiceFinanceImg}
                  alt="Invoice finance image"
                  title="Invoice Finance"
                />
              </Col>
              <Col>
                <ImageCard
                  image={data.assetFinanceImg}
                  alt="Asset finance image"
                  title="Asset Finance"
                />
              </Col>
              <Col>
                <ImageCard
                  image={data.tradeFinanceImg}
                  alt="Trade finance image"
                  title="Trade Finance"
                />
              </Col>
              <Col>
                <ImageCard
                  image={data.exportFinanceImg}
                  alt="Export finance image"
                  title="Export Finance"
                />
              </Col>
              <Col>
                <ImageCard
                  image={data.stockFinanceImg}
                  alt="Stock finance image"
                  title="Stock Finance"
                />
              </Col>
              <Col>
                <ImageCard
                  image={data.propertyFinanceImg}
                  alt="Property finance image"
                  title="Property Finance"
                />
              </Col>
            </Row>
            <Row xs={1} md={2} className="g-4 pt-4">
              <Col>
                <ImageCard
                  image={data.badDebtProtectionImg}
                  alt="Bad debt protection image"
                  title="Bad Debt Protection"
                />
              </Col>
              <Col>
                <ImageCard
                  image={data.constructionFinanceImg}
                  alt="Construction finance image"
                  title="Construction Finance"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container >
    </Layout >
  )
}

export default Commercial;

export const carouselImage = graphql`
  fragment offeringImage on File {
    childImageSharp {
      gatsbyImageData(quality: 85, layout: FULL_WIDTH)
    }
  }
`

export const query = graphql`
  query {
    invoiceFinanceImg: file(relativePath: {eq: "calculator-1112860_640.jpg"}) {
      ...offeringImage
    }
    assetFinanceImg: file(relativePath: {eq: "bank-phrom-Tzm3Oyu_6sk-unsplash.jpg"}) {
      ...offeringImage
    }
    tradeFinanceImg: file(relativePath: {eq: "highway-3392100_640.jpg"}) {
      ...offeringImage
    }
    exportFinanceImg: file(relativePath: {eq: "freighter-315201_640.jpg"}) {
      ...offeringImage
    }
    stockFinanceImg: file(relativePath: {eq: "noodle-kimm-ytMmedc6y44-unsplash.jpg"}) {
      ...offeringImage
    }
    propertyFinanceImg: file(relativePath: {eq: "sean-pollock-PhYq704ffdA-unsplash.jpg"}) {
      ...offeringImage
    }
    badDebtProtectionImg: file(relativePath: {eq: "purse-3548021_640.jpg"}) {
      ...offeringImage
    }
    constructionFinanceImg: file(relativePath: {eq: "bulldozer-2195329_640.jpg"}) {
      ...offeringImage
    }
  }
`
